import { defineStore } from "pinia"
import { StoreTypes } from "../types/store"
import HttpInstance from "../request/server"
import { ActivityType, ChristmasUserPageEnum } from "../types/activity"
import { useUserStore } from "./user"

export type PopupType = "JOIN" | "BONUS" | "RECEIVE_BONUS" | "HELP_SUCCESS"

/** 分享类型 1:助力分享,2領取bouns分享 */
export enum ShareEnum {
  FRIEND = 1,
  BONUS = 2,
}

export interface PopupData {
  uid: number
  activityType: ActivityType
  coin: number
  isHelping: boolean
  username: string
  helpBonus: number
}

/** 活动用户类型 */
export enum HelpEnum {
  // 活动充值者
  USER = 1,
  // 助力者
  HELP = 2,
}

/** 圣诞活动路由 */
export const CHRISTMAS_PATH = "/activity/christmas"

export enum GiftBoxStatus {
  // 不可领取
  NOT_REACHED = "NOT_REACHED",
  // 可领取
  READY_TO_CLAIM = "READY_TO_CLAIM",
  // 已领取
  CLAIMED = "CLAIMED",
}

/** 圣诞活动状态 */
type ChristmasState = {
  helpDetail: StoreTypes.Activity.ChristmasHelpDetail | null // 助力讯息
  stage: ChristmasUserPageEnum // 当前显示的页面
  sharingDrawer: boolean // 是否显示邀请弹窗
  isOpen: boolean | null // 活动是否开启
  activityId: string | null // 活动id
  activityLaunchHelpId?: string | null // 登录的时候要传给服务端，用于绑定助力关系
  uid?: string | null // 用户id
  alreadyPopup: boolean // 是否显示过圣诞弹窗
  liveCarAlreadyPopup: boolean // 是否显示过机车弹窗
  helpDetailCount: number
  popup: {
    type: PopupType
    visible: boolean
    data?: any
    onClose?: () => void
  } | null
}

/** 圣诞节错误码 */
export enum CHRISTMAS_CODE {
  ACTIVE_NOT_FOUND = 30142,
  ACTIVE_NOT_OPEN = 30143,
  NOT_NEED_HELP = 30144,
  GIFT_BOX_IN_REVIEW = 30145,
  CAN_NOT_GET_GIFT_BOX = 30146,
  ALREADY_HELP_THIS_USER = 30147,
  BLACKLIST_CNA_NOT_HELP = 30148,
  CAN_NOT_HELP_MYSELF = 30149,
  BLACKLIST_CNA_NOT_JOIN = 30150,
  THIS_USER_ENOUGH = 30151,
}

/**   礼物盒子的状态，通过applyTime和completePer来判断 */
const getStageStatus = (
  helpDetail: StoreTypes.Activity.ChristmasHelpDetail | null,
  stageNumber: number,
  stages: Array<any>
) => {
  if (!helpDetail) {
    return GiftBoxStatus.NOT_REACHED
  }
  const currentPer = parseFloat(helpDetail.completePer)
  const stagePer = parseFloat(stages[stageNumber - 1] as string)

  if (helpDetail.applyTimes < stageNumber && currentPer >= stagePer) {
    return GiftBoxStatus.READY_TO_CLAIM // 如果applyTimes对应于该阶段前一个，并且完成百分比超过或等于该阶段百分比，则可领取
  } else if (helpDetail.applyTimes >= stageNumber) {
    return GiftBoxStatus.CLAIMED // 如果applyTimes大于或等于该阶段，则已领取
  }
  return GiftBoxStatus.NOT_REACHED // 否则，不可领取
}

export const useChristmasStore = defineStore("Christmas", {
  state: (): ChristmasState => ({
    helpDetail: null,
    stage: ChristmasUserPageEnum.DEPOSIT,
    sharingDrawer: false,
    isOpen: null,
    activityId: null,
    activityLaunchHelpId: null,
    uid: null,
    helpDetailCount: 1,
    popup: null,
    alreadyPopup: false,
    liveCarAlreadyPopup: false,
  }),
  getters: {
    /** 是否为用户上一次活动数据*/
    isPreviousEvent(): boolean {
      return this.helpDetail?.status === -1 && !!this.helpDetail.activityId;
    },
    /** 是否有可领取的奖金 */
    isBonusAvailable(): boolean {
      return !!this.processList.find(
        (item) => item.status === GiftBoxStatus.READY_TO_CLAIM
      )
    },
    /** 奖金需达成的助力百分比 */
    stageList(state): Array<string | undefined> {
      return [
        state.helpDetail?.firstStagePer,
        state.helpDetail?.secondStagePer,
        state.helpDetail?.thirdStagePer,
        "100", // 第四阶段的完成百分比总是100%
      ]
    },
    /** 目前达成的助力百分比 */
    completePer(state): string {
      return state.helpDetail?.completePer || "0"
    },
    /** 每个奖金礼盒的状态 */
    processList(state): StoreTypes.Activity.ProcessItem[] {
      const status = (stageNumber: number) =>
        getStageStatus(state.helpDetail, stageNumber, this.stageList)
      return [
        {
          per: state.helpDetail?.firstStagePer,
          bonus: state.helpDetail?.firstStageBonus,
          status: status(1),
        },
        {
          per: state.helpDetail?.secondStagePer,
          bonus: state.helpDetail?.secondStageBonus,
          status: status(2),
        },
        {
          per: state.helpDetail?.thirdStagePer,
          bonus: state.helpDetail?.thirdStageBonus,
          status: status(3),
        },
        {
          per: "100",
          bonus: state.helpDetail?.fourthStageBonus,
          status: status(4),
        },
      ]
    },
  },
  actions: {
    /** 助力讯息变更 */
    HELP_DETAIL_CHANGE() {
      this.helpDetailCount++
    },
    /** 派奖 */
    async APPLY_COIN(data?: PopupData) {
      if (data && data.activityType === ActivityType.SPECIAL) {
        const raffleManager = useRaffleTicketManager()
        const ticketData = await raffleManager.createRaffleTicket(
          EnumRaffleTicket.XMAS_HELP,
          false
        )

        // isHelping为true表示助力者拿到钱，false为被助力者拿到的钱
        if (data.isHelping) {
          if (!!Number(data.helpBonus)) {
            await this.CHRISTMAS_POPUP({
              data,
              type: "HELP_SUCCESS",
              visible: true,
              onClose: () => {
                this.CHRISTMAS_POPUP({
                  data: {
                    ...data,
                    coin: 0,
                  },
                  type: "HELP_SUCCESS",
                  visible: true,
                })
              },
            })
          }
        } else {
          // 被助力者拿到钱的弹窗
          if (!!Number(data.coin)) {
            await this.CHRISTMAS_POPUP({
              type: "RECEIVE_BONUS",
              visible: true,
              data: { coin: data.coin },
            })
          }
        }
        ticketData && raffleManager.popRaffleTicket(ticketData)
      }
    },
    /** 获取帮助讯息 */
    async FETCH_HELP_DETAIL(type: number) {
      const query = getHybridQuery()
      const userStore = useUserStore()
      if (!userStore.isLogin) {
        return true
      }
      try {
        const { code, data } = await HttpInstance.post("getHelpDetail", {
          type,
          uid:
            type === HelpEnum.USER ? `${userStore.info?.id}` : `${query.uid}`,
        })
        if (code === 0) {
          this.helpDetail = data
          return true
        }
        // 活动未激活
        if ([30142, 30143, 30144, 30150].includes(code)) {
          navigateTo({ name: "Home" })
          return false
        }
      } catch (e) {}
    },
    /** 调整圣诞活动展示 */
    CHANGE_STAGE(stage: ChristmasUserPageEnum) {
      this.stage = stage
    },
    /** 开启邀请弹窗 */
    OPEN_SHARING_DRAWER() {
      this.sharingDrawer = true
    },
    /** 关闭邀请弹窗 */
    CLOSE_SHARING_DRAWER() {
      this.sharingDrawer = false
    },
    /** 跳转到圣诞活动页面 */
    async JUMP_TO_CHRISTMAS() {
      if (await this.FETCH_HELP_DETAIL(HelpEnum.USER)) {
        navigateTo({ path: CHRISTMAS_PATH })
      }
    },
    /** 查询圣诞活动状态 */
    async CHECK_CHRISTMAS_STATUS(activityId: string | number = -1) {
      try {
        const { code, data } = await HttpInstance.post(
          "getChristmasActivityStatus",
          { activityId }
        )
        this.isOpen = Boolean(data)
        return Boolean(data)
      } catch (e) {
        this.isOpen = false
        return false
      }
    },
    /** 查询圣诞帮助的进度 */
    async CHECK_HELP_PROCESS() {
      const query = getHybridQuery()
      const { openDialog } = useDialog()
      const userStore = useUserStore()
      const isOpen = await this.CHECK_CHRISTMAS_STATUS(
        query.activityId as string
      )
      // 活动未开启
      if (!isOpen) {
        this.CHRISTMAS_MESSAGE("The event is not available")
        await navigateTo({
          query: { inactive: "1" },
          path: CHRISTMAS_PATH,
        })
        return
      }
      // 用户未登录跳转登录
      if (!userStore.isLogin) {
        return await openDialog("signIn")
      }
      try {
        const { uid, activityLaunchHelpId, activityId } = query
        const { data, code, msg } = await HttpInstance.post(
          "christmasActivityHelping",
          {
            activityId,
            uid,
            activityLaunchHelpId,
          },
          {},
          { ignoreError: true }
        )

        if (code === 0) {
          switch (data.status) {
            // 0还未KYC，1 kyc还在审核中, 2 已经完成
            case 0:
            case 1:
              await navigateTo({
                query: {
                  ...query,
                  dialogType: "kycSetting",
                },
                path: "/helpActivity/done",
              })
              break
            case 2:
              await navigateTo({
                query,
                path: "/helpActivity/done",
              })
              break
          }
        } else if ([30147, 30151, 30153, 30148, 30150].includes(code)) {
          this.CHRISTMAS_MESSAGE(msg)
          await navigateTo({
            query: { inactive: "1" },
            path: CHRISTMAS_PATH,
          })
        } else {
          this.CHRISTMAS_MESSAGE(msg)
        }
      } catch (e) {}
    },
    /** 发送圣诞消息 */
    CHRISTMAS_MESSAGE(message: string) {
      ElMessage({
        message,
        showClose: true,
        customClass: "christmas-message",
        type: "info",
      })
    },
    /** 圣诞活动特别弹窗 */
    CHRISTMAS_POPUP(popup: ChristmasState["popup"]) {
      this.popup = popup
    },
    /** 关闭圣诞活动特别弹窗 */
    CLOSE_POPUP() {
      if (this.popup?.onClose) {
        this.popup.onClose()
      } else {
        this.popup = null
      }
    },
    /** 参与圣诞活动*/
    async JOIN_EVENT() {
      const userStore = useUserStore()

      // 未登录跳转登录
      if (!userStore.isLogin) {
        navigateTo(
          {
            path: "/activity/christmas",
            query: { dialogType: "PwdSign", dialogOptions: "Deposit" },
          },
          { replace: true }
        )
        return
      }
      // 获取领取次数
      const { data: applyCount } = await HttpInstance.post(
        "getCheckApplyTimes",
        {},
        { ignoreError: true }
      )

      // 次数已经耗尽，需等待第二天
      if (applyCount === 0) {
        this.CHRISTMAS_MESSAGE(
          "you can claim this bonus on next day, after 00:00, thank you for your support"
        )
        return
      }

      // 检查圣诞活动是否开启
      const { code, msg } = await HttpInstance.post(
        "checkChristmasActivity",
        {},
        {},
        { ignoreError: true }
      )
      // 开启则跳转充值
      if (code === 0) {
        navigateTo({
          path: "/my/deposit",
        })
      } else {
        // 否则提示系统返回的消息
        this.CHRISTMAS_MESSAGE(msg)
      }
    },
  },
})
